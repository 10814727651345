import React from 'react';
import { Card, CardContent } from '@bit/matternet.shared.components.card';
import { List, ListItem } from '@bit/matternet.shared.components.list';
import { Flex, Text, Stack, SimpleGrid, Tag } from '@chakra-ui/react';
import { useUIDSeed } from 'react-uid';
import { capitalCase } from 'change-case';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRoute, faChartNetwork } from '@fortawesome/pro-regular-svg-icons';
import { ActiveFlightData } from './types';
import DynamicBatteryIcon from '../shared/DynamicBatteryIcon';

type ActiveFlightsProps = {
  data: ActiveFlightData;
  onClick: (flightPlanId: number) => void;
};

const ActiveFlightContent = ({ data, onClick }: ActiveFlightsProps) => {
  const seed = useUIDSeed();

  if (data && data?.length > 0) {
    const listElements = data.map((activeFlight, idx) => {
      const handleActiveFlightSelection = () => {
        if (activeFlight?.flightPlanId) {
          onClick(activeFlight?.flightPlanId);
        }
      };

      return (
        <ListItem
          key={seed(activeFlight?.flightPlanId || idx)}
          onClick={handleActiveFlightSelection}
        >
          <SimpleGrid spacing={{ sm: 2, md: 4 }} columns={{ md: 2 }}>
            <Stack>
              <Flex>
                <Text mr="2" fontWeight="bold">
                  {activeFlight?.vehicleName}
                </Text>
                <Tag colorScheme="green" rounded="full" size="sm" px={4}>
                  <Text fontSize="xs" fontFamily="mono">
                    {capitalCase(activeFlight?.flightStatus || '')}
                  </Text>
                </Tag>
              </Flex>
              <Stack spacing={2} isInline shouldWrapChildren>
                <DynamicBatteryIcon
                  percentage={activeFlight?.batteryPercentage || 0}
                />
                <Text fontFamily="mono">
                  {`${activeFlight?.batteryPercentage}%`}
                </Text>
              </Stack>
            </Stack>

            <Stack>
              <Stack isInline shouldWrapChildren spacing={2}>
                <FontAwesomeIcon icon={faRoute} />
                <Text>{activeFlight?.flightRouteName}</Text>
              </Stack>

              <Stack isInline shouldWrapChildren spacing={2}>
                <FontAwesomeIcon icon={faChartNetwork} />
                <Text>{activeFlight?.networkName}</Text>
              </Stack>
            </Stack>
          </SimpleGrid>
        </ListItem>
      );
    });

    return <List actionable>{listElements}</List>;
  }

  return (
    <Flex justifyContent="center" px="4" py="10">
      <Stack alignItems="center">
        <Text fontWeight="bold">There are currently no active flights.</Text>
        <Text>If available, launch a flight from Priority Events.</Text>
      </Stack>
    </Flex>
  );
};

const ActiveFlights = ({ data, onClick }: ActiveFlightsProps) => (
  <Card heading="Active Flights">
    <CardContent>
      <ActiveFlightContent data={data} onClick={onClick} />
    </CardContent>
  </Card>
);

export default ActiveFlights;
