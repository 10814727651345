import { PriorityEventsData } from '../components/PriorityEvents/types';

const playSoundIfNewEvent = (
  data: PriorityEventsData,
  prevData?: PriorityEventsData,
) => {
  const count = data.length;
  const prevCount = prevData?.length || 0;
  const isNewEvent = count > 0 && prevCount && count > prevCount;
  const isDataChanged = data.some(
    (event) => !prevData?.some((prevEvent) => event?.id === prevEvent?.id),
  );

  return isNewEvent || isDataChanged;
};

export default playSoundIfNewEvent;
