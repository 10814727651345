import React, { useState } from 'react';
import { Grid } from '@chakra-ui/react';
import { Card, CardContent } from '@bit/matternet.shared.components.card';
import { useUIDSeed } from 'react-uid';
import { Howl } from 'howler';
import { useMount, useUpdateEffect, usePrevious } from 'react-use';
import { PriorityEventsProps } from './types';
import playSoundIfNewEvent from '../../utils/playSoundIfNewEvent';
import PriorityEventCard, {
  PriorityEventPlaceholder,
} from './PriorityEventCard';
import { PRIORITY_EVENTS_LIMIT, PRIORITY_EVENT_SOUND } from './constants';
import AnnunciatorTestHeader from '../shared/AnnunciatorTestHeader';

const priorityEventSound = new Howl({
  src: PRIORITY_EVENT_SOUND,
  preload: true,
});

/**
 * playingEventSoundId is used to uniquely identify a single `play`
 * invocation, we use this to try to prevent sounds playing all at once.
 */
let playingEventSoundId: number;

const PriorityEvents = ({ data, onClick }: PriorityEventsProps) => {
  const [useTest, setTestData] = useState(false);
  const seed = useUIDSeed();

  if (data.length > 3) {
    throw Error(
      'PriorityEvents only supports up to 3 items at this time. Try reducing the number of elements in data.',
    );
  }

  let enhancedData = [...data];
  if (useTest) {
    const testVehicleData = {
      id: 0,
      vehicleName: 'TEST-VEHICLE',
      eventMessage: 'Test Priority Event',
      vehicleId: 0,
      flightPlanId: 0,
      eventType: 'CRUISE',
      batteryPercentage: 52,
    };
    enhancedData = [testVehicleData, ...data].slice(0, 3);
  }

  for (let i = 0; i < PRIORITY_EVENTS_LIMIT; i += 1) {
    if (!enhancedData[i]) {
      enhancedData.push(null);
    }
  }

  const prevData = usePrevious(data);

  const playNewEventSound = () => {
    if (
      (!priorityEventSound.playing(playingEventSoundId) &&
        playSoundIfNewEvent(data, prevData)) ||
      useTest
    ) {
      playingEventSoundId = priorityEventSound.play();
    }
  };

  // A check must happen for the first time the component renders
  useMount(playNewEventSound);

  // Subsequent updates
  useUpdateEffect(playNewEventSound);

  const heading = (
    <AnnunciatorTestHeader
      headerText="Priority Events"
      onSetTestState={setTestData}
    />
  );

  return (
    <Card heading={heading}>
      <CardContent>
        <Grid
          gridTemplateRows="1fr"
          gridTemplateColumns={{
            md: 'repeat(auto-fit, minmax(150px, 1fr))',
          }}
          gap={6}
        >
          {enhancedData.map((priorityEvent, index) => {
            const number = index + 1;

            if (priorityEvent === null) {
              return (
                <PriorityEventPlaceholder key={seed(index)} number={number} />
              );
            }

            return (
              <PriorityEventCard
                id={priorityEvent.id}
                vehicleId={priorityEvent.vehicleId}
                key={seed(index)}
                number={number}
                onPriorityEventCardClick={() => {
                  onClick(priorityEvent.flightPlanId);
                }}
                flightPlanId={priorityEvent.flightPlanId}
                vehicleName={priorityEvent.vehicleName}
                eventType={priorityEvent.eventType}
                eventMessage={priorityEvent.eventMessage}
                batteryPercentage={priorityEvent.batteryPercentage}
              />
            );
          })}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PriorityEvents;
