import React from 'react';
import { Box, Stack, Button } from '@chakra-ui/react';
import Head from 'next/head';
import ContentContainer from '@bit/matternet.shared.components.content-container';
import PriorityEventsView from '@/src/views/priorityEvents';
import ActiveFlightsView from '@/src/views/activeFlights';
import { withApollo } from '@/src/lib/apollo';
import AppAccessCheck from '@/src/utils/AppAccessCheck';
// import CommandsHotkeys from '@/src/views/commands/CommandsHotkeys';
import openRequestedWindow from '@/src/utils/openRequestedWindow';

const Index = () => (
  <AppAccessCheck>
    <Head>
      <title>Priority Events · Matternet Mission Control</title>
    </Head>
    <ContentContainer>
      {/* TODO: If restoring hotkeys, keep in mind access controls! */}
      {/* <CommandsHotkeys /> */}
      <Box>
        <Stack direction="row" mb="6">
          <Button
            colorScheme="indigo"
            variant="outline"
            onClick={() => {
              openRequestedWindow(
                `${window.location.origin}/vehicle`,
                'vehicle',
              );
            }}
          >
            Aircraft Screen
          </Button>
          <Button
            colorScheme="indigo"
            variant="outline"
            onClick={() => {
              openRequestedWindow(`${window.location.origin}/map`, 'map');
            }}
          >
            Map Screen
          </Button>
        </Stack>
        <Stack spacing={4} shouldWrapChildren>
          <PriorityEventsView />
          <ActiveFlightsView />
        </Stack>
      </Box>
    </ContentContainer>
  </AppAccessCheck>
);

// @ts-ignore
export default withApollo({ ssr: false })(Index);
